import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

const SEO = ({ lang, indexed, title, description, image, meta, keywords }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, siteUrl, defaultImage } = site.siteMetadata
  const cardUrl = `${site.siteMetadata.siteUrl}${pathname}card.jpg`
  // console.log(`title: `, title)
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  // console.log(`seo.title: `, seo.title)
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      titleTemplate={title ? `%s | ${site.siteMetadata.title}` : `${site.siteMetadata.title}`}
      meta={[
        {
          name: "robots",
          content: indexed === "false" ? "noindex,nofollow" : "index,follow",
        },
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:url`,
          content: seo.url,
        },

        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:image`,
          content: cardUrl,
        },
        {
          property: `twitter:image`,
          content: cardUrl,
        },
        {
          property: `image`,
          content: cardUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        // {
        //   name: `author`,
        //   content: site.siteMetadata.author,
        // },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          property: `fb:app_id`,
          content: "446271440602007",
        },
      ]
        .concat(
          keywords !== null && keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [
    "spa chicago",
    "spa chicago downtown",
    "lower back pain",
    "spa and wellness",
    "spa gift card",
    "medspa chicago",
    "massage in chicago",
    "med spa chicago",
    "medical spa chicago",
    "med spa in chicago",
    "essence massage",
    "essence day spa",
    "essence filler",
    "essence botox",
    "med spas chicago",
    "Chicago Spa",
    "Chicago Massage",
    "Chicago Medical Spa",
    "Botox",
    "best botox chicago",
    "best medspa chicago",
    "Fillers and Skin Rejuvenation Treatments",
    "IV Drips",
    "Facial Treatments",
    "Therapeutic Massages",
    "Body Treatment",
    "Waxing Treatments",
    "Physical Therapy",
    "swedish massage",
    "deep tissue massage",
  ],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
}

export default SEO

const query = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        defaultTitle: title
        # titleTemplate
        defaultDescription: description
        # siteUrl: url
        # defaultImage: image
        # twitterUsername
      }
    }
  }
`
