export const menuItems = [
  {
    name: "med-spa",
    label: "Med Spa",
    items: [
      {
        name: "services/med-spa/botox-services",
        label: "Botox Services",
      },
      {
        name: "services/med-spa/filler-services",
        label: "Filler Services",
        tag: "hot",
      },
      {
        name: "services/med-spa/hyaluronidase-dissolve",
        label: "Hyaluronidase Dissolve",
        tag: "new",
      },
      {
        name: "services/med-spa/b12-shots",
        label: "B12 Shots",
        tag: "",
      },
      {
        name: "services/med-spa/prp-facial",
        label: "PRP Facial",
        tag: "hot",
      },
      {
        name: "services/med-spa/vampire-facial",
        label: "Vampire Facial",
        tag: "hot",
      },
      {
        name: "services/med-spa/collagen-induction-vitamin-c",
        label: "Collagen with Vitamin C",
      },
      {
        name: "services/med-spa/mesotherapy",
        label: "Mesotherapy",
      },
      {
        name: "services/med-spa/iv-drips",
        label: "IV Drips",
        tag: "",
      },
    ],
  },
  {
    name: "facial-treatments",
    label: "Facial Treatments",
    megamenu: true,
    col: 12,
    items: {
      // image: {
      //   url: "",
      //   col: "",
      // },
      col_1: {
        title: "",
        items: [
          {
            name: "services/facial-treatments/acne-oil-control/",
            label: "Acne & Oil Control Facial",
            tag: "hot",
          },
          {
            name: "services/facial-treatments/brightening-vitamin-c/",
            label: "Brightening Vitamin C Facial",
          },
          {
            name: "services/facial-treatments/collagen-sublime-eye-contour-treatment/",
            label: "Collagen Sublime Eye Contour",
          },
          {
            name: "services/facial-treatments/deep-pore-cleansing/",
            label: "Deep Pore Cleansing Facial",
          },
          {
            name: "services/facial-treatments/signature-facelift-facial/",
            label: "Essence Signature Facelift Facial",
          },
          {
            name: "services/facial-treatments/signature-facelift-peel/",
            label: "Essence Signature Facelift Peel",
          },
          {
            name: "services/facial-treatments/express-teen/",
            label: "Express Teen Facial",
          },
        ],
      },
      col_2: {
        title: "",
        items: [
          {
            name: "services/facial-treatments/forte-peel/",
            label: "Forte Peel",
          },
          {
            name: "services/facial-treatments/forte-peel-and-facial/",
            label: "Forte Peel & Facial",
          },
          {
            name: "services/facial-treatments/hydrofacial/",
            label: "Hydrofacial",
            tag: "hot",
          },
          {
            name: "services/facial-treatments/microdermabrasion-facial/",
            label: "Microdermabrasion Facial",
            tag: "hot",
          },
          {
            name: "services/facial-treatments/oxygen-facial/",
            label: "Oxygen Facial",
          },
          {
            name: "services/facial-treatments/custom-facial/",
            label: "Custom Facial",
            tag: "new",
          },
          {
            name: "services/facial-treatments/powerful-anti-wrinkle-clinical/",
            label: "Powerful Anti-Wrinkle Clinical Facial",
            tag: "trending",
          },
        ],
      },
      col_3: {
        title: "",
        items: [
          {
            name: "services/facial-treatments/rejuvenating-radio-frequency-facial/",
            label: "Rejuvenating Radio Frequency Facial",
            tag: "new",
          },
          {
            name: "services/facial-treatments/signature-clinical-rejuvenating/",
            label: "Signature Clinical Rejuvenating Facial",
            tag: "trending",
          },
          // {
          //   name: "services/facial-treatments/skin-brightening/",
          //   label: "Skin Brightening Facial",
          // },
          {
            name: "services/facial-treatments/stimulator-peel-and-facial/",
            label: "Stimulator Peel & Facial",
          },
          {
            name: "services/facial-treatments/hydrating-facial/",
            label: "Hydrating Facial",
          },
          {
            name: "services/facial-treatments/visible-lifting-and-firming-clinical/",
            label: "Visible Lifting & Firming Clinical Facial",
            tag: "trending",
          },
        ],
      },
    },
  },
  {
    name: "massage",
    label: "Massage",
    items: [
      { name: "services/massage/athletes-massage/", label: "Athlete’s Massage" },
      { name: "services/massage/balinese-bamboo-massage/", label: "Bamboo Massage", tag: "" },
      { name: "services/massage/calming-hot-stone-massage/", label: "Hot Stone Massage" },
      { name: "services/massage/cupping-massage/", label: "Cupping Massage" },
      { name: "services/massage/deep-tissue-massage/", label: "Deep Tissue Massage", tag: "hot" },
      { name: "services/massage/prenatal-massage/", label: "Prenatal Massage" },
      { name: "services/massage/swedish-massage/", label: "Swedish Massage", tag: "hot" },
      { name: "services/massage/thai-herbal-poultice-massage/", label: "Thai Herbal Massage" },
      { name: "services/massage/foot-reflexology/", label: "Foot Reflexology", tag: "new" },
      // { name: "services/massage/lymphatic-drainage-massage/", label: "Lymphatic Drainage Massage" },
    ],
  },
  {
    name: "body-treatments",
    label: "Body Treatments",
    items: [
      {
        name: "services/body-treatments/back-facial-treatment/",
        label: "Back Facial",
        tag: "new",
      },
      { name: "services/body-treatments/back-microdermabrasion/", label: "Back Microdermabrasion", tag: "new" },
      { name: "services/body-treatments/back-stimulator/", label: "Back Stimulator Peel" },
      { name: "services/body-treatments/body-scrub/", label: "Body Scrub" },
      { name: "services/body-treatments/microdermabrasion-chest/", label: "Microdermabrasion Chest", tag: "new" },
      { name: "services/body-treatments/microdermabrasion-neck/", label: "Microdermabrasion Neck", tag: "new" },
      { name: "services/body-treatments/waxing-treatments/", label: "Waxing Hair Removal Service" },
    ],
  },
  {
    name: "services/more",
    label: "More",
    items: [
      { name: "https://www.essence-medspa.com/products", label: "Pilates Class", tag: "hot", isExternal: true },
      { name: "services/private-spa-party/", label: "Private SPArty", tag: "hot" },
      { name: "services/eyebrow-eyelash-services/", label: "Eyebrow / Eyelash" },
      { name: "services/day-pass/", label: "Day Pass", tag: "new" },
      { name: "services/add-ons/", label: "Add Ons" },
      { name: "services/hair-restoration/", label: "Hair Restoration", tag: "new" },
    ],
  },
  { name: "blog/", label: "Blog" },
  {
    name: "https://www.essence-medspa.com/products",
    label: "Products",
    isExternal: true,
  },
  // {
  //   name: "#",
  //   label: "Specials",
  //   isExternal: false,
  // },
]
export default menuItems
