import React, { useState } from "react"
import { Navbar, Button, Offcanvas } from "react-bootstrap"
import siteBrandDark from "~image/logo/logo-black.png"
import siteBrandLight from "~image/logo/essence-white-small.png"
import Menu from "./Menu"
import Config from "~data/config"

const SiteNavbar = ({ buttonBlock, darkLogo, customLogo, defaultLogo, adsPage }) => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="site-navbar">
        <Navbar.Brand href="/">
          {defaultLogo ? (
            <img src={defaultLogo} alt="site-brand" title={`${Config.nameShort} Logo`} className="brand-logo" />
          ) : customLogo ? (
            <img src={customLogo} alt="site-brand" title={`${Config.nameShort} Logo`} className="brand-logo" />
          ) : (
            <img
              src={darkLogo ? siteBrandDark : siteBrandLight}
              alt="site-brand"
              title={`${Config.nameShort} Logo`}
              className="brand-logo"
            />
          )}
        </Navbar.Brand>
        {/* {!adsPage && <Navbar.Toggle aria-controls="responsive-navbar-nav" className="hamburgur ms-3" />} */}
        {!adsPage && (
          <Button onClick={handleShow} className="hamburgur ms-3 navbar-toggler">
            <span className="navbar-toggler-icon"></span>
          </Button>
        )}
        {!adsPage && (
          <>
            <span className="me-0 ms-auto d-none d-lg-inline-flex">
              <Menu />
            </span>
            <Offcanvas show={show} onHide={handleClose} backdrop={true} scroll={false}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Menu show={show} onHide={handleClose} />
              </Offcanvas.Body>
            </Offcanvas>
          </>
        )}
        {buttonBlock}
      </Navbar>
    </>
  )
}

export default SiteNavbar
