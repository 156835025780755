import React from "react"
import { Box, Button } from "~styled"
import { Link } from "~components"

export const MDXButton = ({ text, link, ...props }) => {
  // Internal
  if (link?.startsWith("services")) {
    return (
      <Button>
        <Link link={`/${link}`} className={`btn btn-primary btn-wide rounded-50 btn-rounded btn-sm`} {...props}>
          {text}
        </Link>
      </Button>
    )
  } else if (link?.startsWith("/")) {
    // Internal Link
    return (
      <Link link={`${link}`} className={`btn btn-primary btn-wide rounded-50 btn-rounded btn-sm`} {...props}>
        {text}
      </Link>
    )
  } else if (link?.startsWith("http")) {
    // External Link
    return (
      <a href={link} className={`btn-link btn-inline`} target="_blank" rel="noopener noreferrer" {...props}>
        {text}
      </a>
    )
  } else {
    // Vagaro Link
    return (
      <Button>
        <a
          href={`https://www.vagaro.com/essencemedspaandwellnesscenter/book-now/${link}?partner=fb&ig_fbb=true`}
          className={`btn btn-primary btn-wide rounded-50 btn-rounded btn-sm`}
          target="_blank"
          rel="noopener noreferrer"
          {...props}>
          {text}
        </a>
      </Button>
    )
  }
}
