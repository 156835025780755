import React from "react"
import { SuperTag } from "~components"
import SectionTitle from "./style"
const SectionTitleBlock = ({ pricing, subTitleProps, titleProps, textProps, subTitle, title, text, center }) => {
  return (
    <SectionTitle>
      {subTitle && (
        <SectionTitle.Subtitle {...subTitleProps}>
          <SuperTag value={subTitle} />
        </SectionTitle.Subtitle>
      )}
      {title && (
        <SectionTitle.Main {...titleProps} as="h2" mb="3" center={center}>
          <SuperTag value={title} />
        </SectionTitle.Main>
      )}
      {pricing ? (
        <SectionTitle.Price {...titleProps} as="p">
          <SuperTag value={"Price : " + pricing} />
        </SectionTitle.Price>
      ) : null}
      {text ? (
        <SectionTitle.Text {...textProps} center={center}>
          <SuperTag value={text} />
        </SectionTitle.Text>
      ) : null}
    </SectionTitle>
  )
}
export default SectionTitleBlock
