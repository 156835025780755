import React from "react"
import { Helmet } from "react-helmet"
import favicon from "~image/favicon.png"
import Header from "../Header"

// const { formattedTotalPrice, redirectToCheckout, cartCount, clearCart } = useShoppingCart()

export default function Layout({ children }) {
  // const sc = useShoppingCart()
  // console.log({ sc })
  return (
    <>
      <Helmet>
        <title>Essence Med Spa and Wellness Center</title>
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <Header />
      {children}
    </>
  )
}
