import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const LinkComponent = ({ children, className, link, ...rest }) => {
  if (link?.startsWith("+")) {
    // console.log(`Call Link`)
    // console.log(link)
    // console.log("---------")
    // Call Link
    return (
      <a href={`tel:${link}`} target="_blank" className="btn btn-primary rounded-pill py-3 me-auto w-100">
        {children}
      </a>
    )
  } else if (link?.startsWith("mailto:")) {
    // console.log(`Email Link`)
    // console.log(link)
    // console.log("---------")
    // Email Link
    return (
      <a href={`mailto:${link}`} target="_blank" className="btn btn-primary rounded-pill py-3 me-auto w-100">
        {children}
      </a>
    )
  } else if (link?.includes("vagaro")) {
    // console.log(`Vagaro Link`)
    // console.log(link)
    // console.log("---------")
    // Vagaro Link
    return (
      <OutboundLink href={link} target="_blank" rel="noopener noreferrer" className={className} {...rest}>
        {children}
      </OutboundLink>
    )
  } else if (link?.includes("https://essence-medspa.com/book")) {
    // console.log(`Vagaro Link`)
    // console.log(link)
    // console.log("---------")
    // Vagaro Link
    return (
      <Link to={link} className={className} target="_blank" rel="noopener noreferrer" {...rest}>
        {children}
      </Link>
    )
  } else if (!link?.includes("vagaro") && link?.includes("http")) {
    // console.log(`External Link`)
    // console.log(link)
    // console.log("---------")
    // External Link
    return (
      <OutboundLink href={link} target="_blank" rel="noopener noreferrer" className={className}>
        {children}
      </OutboundLink>
    )
  } else if (!link?.startsWith("+") && !link?.includes("http") && link) {
    // console.log(`Internal Link`)
    // console.log(link)
    // console.log("---------")
    // Internal Link
    return (
      <Link to={link} className={className} {...rest}>
        {children}
      </Link>
    )
  } else {
    return <></>
  }
}

export default LinkComponent
