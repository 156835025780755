import React from "react"
import { Col, Container, Nav, NavDropdown, Row, Badge } from "react-bootstrap"
import { menuItems } from "~data/menudata"
import { Link } from "~components/Core"

const isObject = function (a) {
  return !!a && a.constructor === Object
}
const Menu = (props, show) => {
  // console.log({ menuItems })
  return (
    <>
      <div>
        <Nav className="ms-auto site-menu-main">
          {menuItems.map(({ label, isExternal = false, name, items, col, ...rest }, index) => {
            const hasSubItems = Array.isArray(items)
            const hasMegaMenu = isObject(items)

            return (
              <React.Fragment key={name + index}>
                {hasMegaMenu && (
                  <NavDropdown
                    title={label}
                    id={name + index}
                    renderMenuOnMount={true}
                    className={`main-nav-item megadropdown ${{ show }}`}>
                    {/* {console.log(`show: `, show)} */}
                    <Container>
                      <Row>
                        {items.image && (
                          <Col lg={items.image.col} className="d-none d-lg-block">
                            <img src={items.image.url} alt="menu" className="w-100" />
                          </Col>
                        )}
                        {col > 0 && (
                          <>
                            <Col lg={col} className="mega-dropdown-menu-block row">
                              {items.col_1 && (
                                <Col>
                                  {items.col_1.title && (
                                    <span className="megamenu-column-title">{items.col_1.title}</span>
                                  )}
                                  {items.col_1.items.map((item, index) => {
                                    return (
                                      <Nav.Item className="megamenu-nav-item" key={index + "c1"}>
                                        <Link link={`/${item.name}`} title={item.label} onClick={props.onHide}>
                                          <>
                                            {item.tag === "new" && (
                                              <>
                                                {item.label} <Badge bg="niagara">New</Badge>
                                              </>
                                            )}
                                            {item.tag === "hot" && (
                                              <>
                                                {item.label} <Badge bg="torch-red">Hot</Badge>
                                              </>
                                            )}
                                            {item.tag === "trending" && (
                                              <>
                                                {item.label} <Badge bg="purple-heart">Trending</Badge>
                                              </>
                                            )}
                                            {!item.tag && item.label}
                                          </>
                                        </Link>
                                      </Nav.Item>
                                    )
                                  })}
                                </Col>
                              )}
                              {items.col_2 && (
                                <Col>
                                  {items.col_2.title && (
                                    <span className="megamenu-column-title">{items.col_2.title}</span>
                                  )}
                                  {items.col_2.items.map((item, index) => {
                                    return (
                                      <Nav.Item className="megamenu-nav-item" key={index + "c2"}>
                                        <Link link={`/${item.name}`} title={item.label} onClick={props.onHide}>
                                          <>
                                            {item.tag === "new" && (
                                              <>
                                                {item.label} <Badge bg="niagara">New</Badge>
                                              </>
                                            )}
                                            {item.tag === "hot" && (
                                              <>
                                                {item.label} <Badge bg="torch-red">Hot</Badge>
                                              </>
                                            )}
                                            {item.tag === "trending" && (
                                              <>
                                                {item.label} <Badge bg="purple-heart">Trending</Badge>
                                              </>
                                            )}
                                            {!item.tag && item.label}
                                          </>
                                        </Link>
                                      </Nav.Item>
                                    )
                                  })}
                                </Col>
                              )}
                              {items.col_3 && (
                                <Col>
                                  {items.col_3.title && (
                                    <span className="megamenu-column-title">{items.col_3.title}</span>
                                  )}
                                  {items.col_3.items.map((item, index) => {
                                    return (
                                      <Nav.Item className="megamenu-nav-item" key={index + "c4"}>
                                        <Link link={`/${item.name}`} title={item.label} onClick={props.onHide}>
                                          <>
                                            {item.tag === "new" && (
                                              <>
                                                {item.label} <Badge bg="niagara">New</Badge>
                                              </>
                                            )}
                                            {item.tag === "hot" && (
                                              <>
                                                {item.label} <Badge bg="torch-red">Hot</Badge>
                                              </>
                                            )}
                                            {item.tag === "trending" && (
                                              <>
                                                {item.label} <Badge bg="purple-heart">Trending</Badge>
                                              </>
                                            )}
                                            {!item.tag && item.label}
                                          </>
                                        </Link>
                                      </Nav.Item>
                                    )
                                  })}
                                </Col>
                              )}
                              {items.col_4 && (
                                <Col>
                                  {items.col_4.title && (
                                    <span className="megamenu-column-title">{items.col_4.title}</span>
                                  )}
                                  {items.col_4.items.map((item, index) => {
                                    return (
                                      <Nav.Item className="megamenu-nav-item" key={index + "c5"}>
                                        <Link link={`/${item.name}`} title={item.label} onClick={props.onHide}>
                                          <>
                                            {item.tag === "new" && (
                                              <>
                                                {item.label} <Badge bg="niagara">New</Badge>
                                              </>
                                            )}
                                            {item.tag === "hot" && (
                                              <>
                                                {item.label} <Badge bg="torch-red">Hot</Badge>
                                              </>
                                            )}
                                            {item.tag === "trending" && (
                                              <>
                                                {item.label} <Badge bg="purple-heart">Trending</Badge>
                                              </>
                                            )}
                                            {!item.tag && item.label}
                                          </>
                                        </Link>
                                      </Nav.Item>
                                    )
                                  })}
                                </Col>
                              )}
                              {items.col_5 && (
                                <Col>
                                  {items.col_5.title && (
                                    <span className="megamenu-column-title">{items.col_5.title}</span>
                                  )}
                                  {items.col_5.items.map((item, index) => {
                                    return (
                                      <Nav.Item className="megamenu-nav-item" key={index + "c6"}>
                                        <Link link={`/${item.name}`} title={item.label} onClick={props.onHide}>
                                          <>
                                            {item.tag === "new" && (
                                              <>
                                                {item.label} <Badge bg="niagara">New</Badge>
                                              </>
                                            )}
                                            {item.tag === "hot" && (
                                              <>
                                                {item.label} <Badge bg="torch-red">Hot</Badge>
                                              </>
                                            )}
                                            {item.tag === "trending" && (
                                              <>
                                                {item.label} <Badge bg="purple-heart">Trending</Badge>
                                              </>
                                            )}
                                            {!item.tag && item.label}
                                          </>
                                        </Link>
                                      </Nav.Item>
                                    )
                                  })}
                                </Col>
                              )}
                              {items.col_6 && (
                                <Col>
                                  {items.col_6.title && (
                                    <span className="megamenu-column-title">{items.col_1.title}</span>
                                  )}
                                  {items.col_6.items.map((item, index) => {
                                    return (
                                      <Nav.Item className="megamenu-nav-item" key={index + "c7"}>
                                        <Link link={`/${item.name}`} title={item.label} onClick={props.onHide}>
                                          <>
                                            {item.tag === "new" && (
                                              <>
                                                {item.label} <Badge bg="niagara">New</Badge>
                                              </>
                                            )}
                                            {item.tag === "hot" && (
                                              <>
                                                {item.label} <Badge bg="torch-red">Hot</Badge>
                                              </>
                                            )}
                                            {item.tag === "trending" && (
                                              <>
                                                {item.label} <Badge bg="purple-heart">Trending</Badge>
                                              </>
                                            )}
                                            {!item.tag && item.label}
                                          </>
                                        </Link>
                                      </Nav.Item>
                                    )
                                  })}
                                </Col>
                              )}
                            </Col>
                          </>
                        )}
                      </Row>
                    </Container>
                  </NavDropdown>
                )}
                {hasSubItems && (
                  <NavDropdown
                    title={label}
                    id={name + index}
                    className="main-dropdown main-nav-item"
                    renderMenuOnMount={true}
                    {...rest}>
                    <Container>
                      {items.map((subItem, indexSub) => {
                        const hasInnerSubItems = Array.isArray(subItem.items)
                        return (
                          <React.Fragment key={subItem.name + indexSub}>
                            {hasInnerSubItems ? (
                              <NavDropdown
                                title={subItem.label}
                                id={subItem.name + indexSub}
                                renderMenuOnMount={true}
                                className="drop-menu-item innerDropdown">
                                <Container>
                                  {subItem.items.map((itemInner, indexInnerMost) => (
                                    <Nav.Item className="drop-menu-item" key={itemInner.name + indexInnerMost}>
                                      {itemInner.isExternal ? (
                                        <NavDropdown.Item
                                          href={`${itemInner.name}`}
                                          target="_blank"
                                          rel="noopener noreferrer">
                                          {itemInner.label}
                                        </NavDropdown.Item>
                                      ) : (
                                        <Link link={`/${itemInner.name}`} title={itemInner.label}>
                                          {itemInner.label}
                                        </Link>
                                      )}
                                    </Nav.Item>
                                  ))}
                                </Container>
                              </NavDropdown>
                            ) : (
                              <>
                                {subItem.isExternal ? (
                                  <Nav.Item className="drop-menu-item">
                                    <NavDropdown.Item
                                      href={`${subItem.name}`}
                                      target="_blank"
                                      rel="noopener noreferrer">
                                      <>
                                        {subItem.tag === "new" && (
                                          <>
                                            {subItem.label} <Badge bg="niagara">New</Badge>
                                          </>
                                        )}
                                        {subItem.tag === "hot" && (
                                          <>
                                            {subItem.label} <Badge bg="torch-red">Hot</Badge>
                                          </>
                                        )}
                                        {subItem.tag === "trending" && (
                                          <>
                                            {subItem.label} <Badge bg="purple-heart">Trending</Badge>
                                          </>
                                        )}
                                        {!subItem.tag && subItem.label}
                                      </>
                                    </NavDropdown.Item>
                                  </Nav.Item>
                                ) : (
                                  <Nav.Item className="drop-menu-item">
                                    <Link link={`/${subItem.name}`} title={subItem.label} onClick={props.onHide}>
                                      <>
                                        {subItem.tag === "new" && (
                                          <>
                                            {subItem.label} <Badge bg="niagara">New</Badge>
                                          </>
                                        )}
                                        {subItem.tag === "hot" && (
                                          <>
                                            {subItem.label} <Badge bg="torch-red">Hot</Badge>
                                          </>
                                        )}
                                        {subItem.tag === "trending" && (
                                          <>
                                            {subItem.label} <Badge bg="purple-heart">Trending</Badge>
                                          </>
                                        )}
                                        {!subItem.tag && subItem.label}
                                      </>
                                    </Link>
                                  </Nav.Item>
                                )}
                              </>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </Container>
                  </NavDropdown>
                )}

                {!hasSubItems && !hasMegaMenu && (
                  <>
                    {isExternal ? (
                      <Nav.Item className="main-nav-item">
                        <Nav.Link className="nav-link" href={`${name}`} target="_blank" rel="noopener noreferrer">
                          {label}
                        </Nav.Link>
                      </Nav.Item>
                    ) : (
                      <Nav.Item className="main-nav-item">
                        <Link className="nav-link" link={`/${name}`} role="button" aria-expanded="false" title={label}>
                          {label}
                        </Link>
                      </Nav.Item>
                    )}
                  </>
                )}
              </React.Fragment>
            )
          })}
        </Nav>
      </div>
    </>
  )
}
export default Menu
