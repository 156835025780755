import React from "react"
import GlobalHeaderContext, { headerDefault } from "../../../context/GlobalHeaderContext"
// import HeaderButton from "../Header/InnerPageHeader"

const PageWrapper = ({ children, headerConfig = null, innerPage = false }) => {
  const innerPageDefault = {
    headerClasses: "light-header site-header--menu-end site-header--button-sep position-relative",
    containerFluid: false,
    darkLogo: true,
  }
  const activeHeader = innerPage ? innerPageDefault : headerDefault
  const sitectx = React.useContext(GlobalHeaderContext)

  React.useEffect(() => {
    sitectx.changeHeader({ ...activeHeader, ...headerConfig })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <main>
      {/* <Nav /> */}
      {children}
    </main>
  )
}

export default PageWrapper
