import styled from "styled-components/macro"
import { Box, Heading, Button, Paragraph } from "~styled"

const AdIntro = styled(Box)`
  padding-top: 60px;
  padding-bottom: 20px;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 42px;
  }

  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 110px;
  }
  position: relative;
  z-index: 2;
`
AdIntro.Content = styled(Box)``
AdIntro.Title = styled(Heading)`
  font-size: 30px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  position: relative;
  @media (min-width: 768px) {
    font-size: 34px;
  }

  @media (min-width: 992px) {
    font-size: 38px;
  }
`
AdIntro.Subtitle = styled(Heading)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.75;
`
AdIntro.Text = styled(Paragraph)`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.77777;
`
AdIntro.ButtonGroup = styled(Box)`
  justify-content: ${(props) => (props.center ? "center" : "left")};
  margin-right: -17.0656px;
  display: flex;
  flex-wrap: wrap;
`

AdIntro.Image = styled(Box)`
  box-shadow: 0 12px 40px 4px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;

  .video-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: var(--bs-secondary);
    border-color: transparent;

    min-width: 76px;
    max-width: 76px;
    min-height: 76px;
    max-height: 76px;
    @media (min-width: 575px) {
      min-width: 96px;
      max-width: 96px;
      min-height: 96px;
      max-height: 96px;
    }
  }
`

AdIntro.Button = styled(Button)`
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: inherit;
  transition: 0.4s;
  @media (min-width: 576px) {
    font-size: 15px;
  }
  @media (min-width: 1200px) {
    min-width: 165px;
    height: 60px;
  }

  i {
    margin-left: 10px;
  }

  &.btn-1 {
    box-shadow: none;
    &:hover {
      box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
      color: #fff;
    }
  }
  &.btn-2 {
    box-shadow: none;
    &:hover {
      box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
      color: #fff;
    }
  }
`

// AdIntro.Button = styled(Link)`
//   margin-right: 15px;
//   border-radius: 500px;
//   color: #fff;
//   min-width: 140px;
//   height: 56px;
//   margin-top: 15px;
//   font-size: 14px;
//   @media (min-width: 576px) {
//     font-size: 15px;
//   }
//   @media (min-width: 1200px) {
//     min-width: 165px;
//     height: 60px;
//   }
//   &.btn-1 {
//     box-shadow: none;
//     &:hover {
//       box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
//       color: #fff;
//     }
//   }
//   &.btn-2 {
//     box-shadow: none;
//     &:hover {
//       box-shadow: 4px 4px 56px rgba(255, 87, 34, 0.3);
//       color: #fff;
//     }
//   }
// `

AdIntro.Box = styled(Box)``

export default AdIntro
