import styled from "styled-components/macro"

const Logo = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  @media (min-width: 768px) {
    padding-top: 130px;
    padding-bottom: 75px;
  }
  @media (min-width: 992px) {
    padding-top: 195px;
    padding-bottom: 214px;
  }
`

export default Logo
