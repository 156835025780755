import ContentSectionRight from "~components/ContentRight";
import { Images } from "~data";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import medspaImage from "~image/essence/homepage/med-spa.png";
import * as React from 'react';
export default {
  ContentSectionRight,
  Images,
  getImage,
  GatsbyImage,
  StaticImage,
  medspaImage,
  React
};