import React from "react"

import { Col, Container, Row } from "react-bootstrap"
import { Link } from "~components"
import Content from "./style"
import SectionTitle from "./Components/SectionTitle"
import Video from "~components/VideoModal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXButton } from "~components/MDXButton"

export default function ContentSectionRight(props) {
  const {
    image,
    subtitle,
    title,
    description,
    ctaText1,
    ctaLink1,
    ctaText2,
    ctaLink2,
    video,
    cta1Price,
    cta2Price,
    mdx,
  } = props

  let pricing = cta1Price ? cta1Price : ""
  pricing += cta2Price ? " / " + cta2Price : ""

  return (
    <Content>
      <Content.Box pt="60px" pb="0" ptMD="80px" pbMD="0" ptXL="100px" pbXL="0">
        <Container>
          <Row className="align-items-center justify-content-space-between">
            <Content.Container>
              {image && (
                <Content.Image>
                  {mdx && <GatsbyImage image={getImage(image)} alt={"caption"} />}
                  {!mdx && image}
                  {video && (
                    <Video id={video} className="video-btn sonar-emitter">
                      {" "}
                      <i className="fa fa-play" />{" "}
                    </Video>
                  )}
                </Content.Image>
              )}

              <Content.Block>
                <SectionTitle
                  pricing={pricing}
                  subTitle={subtitle}
                  title={title}
                  text={description}
                  titleProps={{ mb: "30px" }}
                  subTitleProps={{ mb: "1rem" }}
                />
                <Row className="align-items-center justify-content-space-between mt-4 mt-lg-0">
                  <Col xs="12" className="col-xl-6 col-lg-6">
                    {ctaLink1 && ctaText1 && mdx && (
                      <MDXButton
                        className="btn btn-primary rounded-pill me-auto w-100 btn-sm"
                        link={ctaLink1}
                        text={ctaText1}>
                        {ctaText1}
                      </MDXButton>
                    )}
                    {ctaLink1 && ctaText1 && !mdx && (
                      <Link
                        className="btn btn-primary rounded-pill py-3 me-auto w-100"
                        link={ctaLink1}
                        title={ctaText1}>
                        {ctaText1}
                      </Link>
                    )}
                  </Col>
                  <Col xs="12" className="col-xl-6 col-lg-6">
                    {ctaLink2 && ctaText2 && mdx && (
                      <MDXButton
                        className="btn btn-primary rounded-pill me-auto w-100 btn-sm"
                        link={ctaLink2}
                        text={ctaText2}>
                        {ctaText2}
                      </MDXButton>
                    )}
                    {ctaLink2 && ctaText2 && !mdx && (
                      <Link
                        className="btn btn-secondary rounded-pill py-3 me-auto mt-4 mt-lg-0 w-100"
                        link={ctaLink2}
                        title={ctaText2}>
                        {ctaText2}
                      </Link>
                    )}
                  </Col>
                </Row>
              </Content.Block>
            </Content.Container>
          </Row>
        </Container>
      </Content.Box>
    </Content>
  )
}
