import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import React, { useContext, useState } from "react"
import { Container } from "react-bootstrap"
import GlobalHeaderContext from "../../../context/GlobalHeaderContext"
import SiteNav from "./SiteNav"
import SiteHeader from "./style"

const Header = () => {
  const [showScrolling, setShowScrolling] = useState(false)
  const [showReveal, setShowReveal] = useState(false)
  const [hasMounted, setHasMounted] = React.useState(false)
  const headerContext = useContext(GlobalHeaderContext)
  const header = headerContext.header

  useScrollPosition(({ currPos }) => {
    if (currPos.y < 0) {
      setShowScrolling(true)
    } else {
      setShowScrolling(false)
    }
    if (currPos.y < -300) {
      setShowReveal(true)
    } else {
      setShowReveal(false)
    }
  })

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <>
      <SiteHeader
        className={`${header.headerClasses} ${showScrolling ? "scrolling" : ""} ${showReveal ? "reveal-header " : ""}`}>
        {/* {header.adsPage && <PromoBar />} */}
        <Container fluid={header.containerFluid ? true : false} className="header-container py-0">
          <SiteNav
            defaultLogo={header.defaultLogo}
            customLogo={header.customLogo}
            darkLogo={header.darkLogo}
            buttonBlock={header.buttonBlock}
            adsPage={header.adsPage}
          />
        </Container>
      </SiteHeader>
    </>
  )
}
export default Header
