import AdIntro from "~components/AdIntro";
import { StaticImage } from "gatsby-plugin-image";
import { Images } from "~data";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  AdIntro,
  StaticImage,
  Images,
  getImage,
  GatsbyImage,
  React
};