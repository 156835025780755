// import React from "react"
// import "./node_modules/react-modal-video/css/modal-video.min.css"
// import "./src/assets/fonts/fontawesome-5/css/all.min.css"
// import { Layout } from "./src/components/Core"
// import { GlobalHeaderProvider } from "./src/context/GlobalHeaderContext"
// import "./src/styles/scss/global.scss"
// import "./src/styles/scss/bootstrap.scss"

// export function wrapPageElement({ element, props }) {
//   return (
//     // <CartProvider
//     //   mode="payment"
//     //   cartMode="client-only"
//     //   stripe={process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}
//     //   successUrl={`${window.location.origin}/thank-you/`}
//     //   cancelUrl={`${window.location.origin}/cart/`}
//     //   currency="USD"
//     //   allowedCountries={["US"]}
//     //   billingAddressCollection={true}>
//     <Layout {...props}>{element}</Layout>
//     // </CartProvider>
//   )
// }

// export const wrapRootElement = ({ element }) => <GlobalHeaderProvider>{element}</GlobalHeaderProvider>

import React from "react"
import "./node_modules/react-modal-video/css/modal-video.min.css"
import "./src/assets/fonts/fontawesome-5/css/all.min.css"
import { Layout } from "./src/components/Core"
import { GlobalHeaderProvider } from "./src/context/GlobalHeaderContext"
import "./src/styles/scss/bootstrap.scss"
import "./src/styles/scss/global.scss"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => <GlobalHeaderProvider>{element}</GlobalHeaderProvider>
