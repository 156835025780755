import LogoBlack from "~image/logo/logo-black.png"
import LogoWhite from "~image/logo/logo-white.png"

import herol4ImgOverlay from "~image/home-services/hero-overlay.png"
// import videoContentImg from "~image/home-agency/about-us-l5-img.png"
import giftCardImage from "~image/essence/giftcard.jpg"

import christmasBanner1 from "~image/essence/homepage/banner-christmas.jpg"
import BannerGiftCards1 from "~image/essence/homepage/Banner-GiftCards-1.jpg"
import BannerGalentine2 from "~image/essence/homepage/Banner-Galentine-2.jpg"
import BannerGalentine1 from "~image/essence/homepage/Banner-Galentine-1.jpg"
import BannerSuite2 from "~image/essence/homepage/Banner-Suite-2.jpg"
import BannerSuite1 from "~image/essence/homepage/Banner-Suite-1.jpg"
import BannerJuvederm2 from "~image/essence/homepage/Banner-Juvederm-2.jpg"
import BannerJuvederm1 from "~image/essence/homepage/Banner-Juvederm-1.jpg"
import BannerGiftCards2 from "~image/essence/homepage/Banner-GiftCards-2.jpg"

import BannerCouples from "~image/essence/homepage/valentine/couples-suite-valentine.jpg"
import BannerGiftcards from "~image/essence/homepage/valentine/gift-card-valentine.jpg"
import BannerJuvederm from "~image/essence/homepage/valentine/juvederm-valentine.jpg"
import BannerGalentine from "~image/essence/homepage/valentine/galentine-valentine.jpg"

import HomepageSpa from "~image/essence/homepage/homepage-spa.jpg"

import homepageStartSection from "~image/essence/homepage/homepage-start.jpg"
import healthAndSafety from "~image/essence/homepage/health-safety.jpg"
import avatarPlaceholder from "~image/essence/homepage/placeholder-avatar.jpg"

import medSpa from "~image/essence/homepage/med-spa.png"
import therapeuticMassage from "~image/essence/homepage/therapeutic-massage.jpg"
import facialTreatments from "~image/essence/homepage/facial-treatments.jpg"
import appointmentSection from "~image/essence/homepage/appointment-section.jpg"

import rightImage from "~image/essence/homepage/homepage-start.jpg"
import leftImage from "~image/essence/homepage/homepage-buygiftcards.jpg"

import medspaImage from "~image/essence/homepage/med-spa.png"
import faceImage from "~image/essence/homepage/facial-treatments.jpg"
import massageImage from "~image/essence/homepage/massage.jpg"
import bodyImage from "~image/essence/homepage/body-treatments.jpg"

const Images = {
  christmas: {
    christmasBanner1: christmasBanner1,
  },
  sections: {
    homepageStartSection: homepageStartSection,
    healthAndSafety: healthAndSafety,
    avatarPlaceholder: avatarPlaceholder,
  },
  essence: {
    hero: {
      BannerGiftCards1: BannerGiftCards1,
      BannerGalentine2: BannerGalentine2,
      BannerGalentine1: BannerGalentine1,
      BannerSuite2: BannerSuite2,
      BannerSuite1: BannerSuite1,
      BannerJuvederm2: BannerJuvederm2,
      BannerJuvederm1: BannerJuvederm1,
      BannerGiftCards2: BannerGiftCards2,
      valentine: {
        couples: BannerCouples,
        giftcards: BannerGiftcards,
        juvederm: BannerJuvederm,
        galentine: BannerGalentine,
      },
    },
    homepage: {
      HomepageSpa: HomepageSpa,
      medSpa: medSpa,
      therapeuticMassage: therapeuticMassage,
      facialTreatments: facialTreatments,
      appointmentSection: appointmentSection,
      rightImage: rightImage,
      leftImage: leftImage,
      medspaImage: medspaImage,
      faceImage: faceImage,
      massageImage: massageImage,
      bodyImage: bodyImage,
    },
  },
  giftCards: {
    heroImg: giftCardImage,
    heroImgOverlay: herol4ImgOverlay,
    // videoContentImg: videoContentImg,
    // aboutUsImg1: aboutUsl5Img1,
    // testiMonialUserimg1: l4testiMonialUserimg1,
    // testiMonialUserimg2: l4testiMonialUserimg2,
    // testiMonialUserimg3: l4testiMonialUserimg3,
    // promoBg: l5promoBg,
  },
  Logo: {
    Black: LogoBlack,
    White: LogoWhite,
  },
}

export default Images
