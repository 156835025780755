import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage as Img } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "~components"
import AdIntro from "./style"
import SectionTitle from "~components/SectionTitle"

export default function AdIntroSection({
  color,
  category,
  image,
  title,
  text,
  cta1,
  cta1Text,
  cta2,
  cta2Text,
  cta1Price,
  cta2Price,
  style = {},
}) {
  const gatsbyImage = getImage(image)

  let pricing = cta1Price ? cta1Price : ""
  pricing += cta2Price ? " / " + cta2Price : ""

  // console.log({ pricing })
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <AdIntro style={style}>
          {/* <Container className=""> */}
          <Row className="align-items-center justify-content-center">
            {gatsbyImage && (
              <>
                <Col xs="12" className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-10 col-xs-12">
                  <AdIntro.Image backgroundImage={gatsbyImage}>
                    <Img
                      image={gatsbyImage}
                      imgClassName="w-100"
                      className="w-100"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      loading="eager"
                      alt=""
                    />
                  </AdIntro.Image>
                </Col>
                <Col col="12" className="col-xxl-6 col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                  <AdIntro.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
                    <SectionTitle
                      color={color}
                      subTitle={category}
                      title={title}
                      text={text}
                      titleProps={{ mb: "30px" }}
                      subTitleProps={{ mb: "25px" }}
                      pricing={pricing}
                    />
                    <AdIntro.ButtonGroup mt="25px">
                      {cta1Text && cta1 && (
                        <AdIntro.Button
                          className="btn btn-secondary rounded-pill py-3 me-auto btn-1"
                          as={Link}
                          link={cta1}
                          target="_blank"
                          rel="noopener noreferrer">
                          {cta1Text}
                        </AdIntro.Button>
                      )}
                      {cta2Text && cta2 && (
                        <AdIntro.Button
                          className="btn btn-primary rounded-pill py-3 me-auto btn-2"
                          as={Link}
                          link={cta2}
                          target="_blank"
                          rel="noopener noreferrer">
                          {cta2Text}
                        </AdIntro.Button>
                      )}
                    </AdIntro.ButtonGroup>
                  </AdIntro.Content>
                </Col>
              </>
            )}
            {!gatsbyImage && (
              <>
                <Col col="12" className="col-lg-6 col-md-8 col-xs-12">
                  <AdIntro.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
                    <SectionTitle
                      subTitle={category}
                      title={title}
                      text={text}
                      titleProps={{ mb: "30px" }}
                      subTitleProps={{ mb: "25px" }}
                      center={!gatsbyImage}
                    />
                    <AdIntro.ButtonGroup mt="25px" center={!gatsbyImage}>
                      {cta1Text && cta1 && (
                        <AdIntro.Button
                          className="btn btn-primary rounded-pill py-3 me-auto"
                          as={Link}
                          link={`https://www.vagaro.com/essencemedspaandwellnesscenter/book-now/${cta1}?partner=fb&ig_fbb=true`}
                          target="_blank"
                          rel="noopener noreferrer">
                          {cta1Text}
                        </AdIntro.Button>
                      )}
                      {cta2Text && cta2 && (
                        <AdIntro.Button
                          className="btn btn-secondary rounded-pill py-3 me-auto"
                          as={Link}
                          link={`https://www.vagaro.com/essencemedspaandwellnesscenter/book-now/${cta2}?partner=fb&ig_fbb=true`}
                          target="_blank"
                          rel="noopener noreferrer">
                          {cta2Text}
                        </AdIntro.Button>
                      )}
                    </AdIntro.ButtonGroup>
                  </AdIntro.Content>
                </Col>
              </>
            )}
          </Row>
          {/* </Container> */}
        </AdIntro>
      )}
    />
  )
}
