export const Config = {
  name: "Essence Med Spa and Wellness Center",
  siteName: "Essence Med Spa and Wellness Center",
  siteDescription: "Begin your wellness journey by healing your body, mind and soul.",
  title: "Chicago’s Premier MedSpa and Wellness Center",
  description:
    "First facility in Chicago to incorporate all aspects of Wellness to incorporate all aspects of Aesthetic Treatments, Cosmetic Procedures, Spa Service and Wellness Center. Experience the difference of Essence.",
  // siteUrl: "https://www.essence-medspa.com",
  siteUrl: "https://essence-medspa.com",
  nameShort: "Essence",
  logoSubtitle: "Med Spa and Wellness Center",
  logo: "/assets/image/logo/essence-new-logo-out-256.png",
  favicon: "/assets/image/favicon.png",
  twitterhandle: "@essencechicago",
  socialLinks: {
    facebook: "https://www.facebook.com/EssenceMedSpaChicago/",
    twitter: "https://twitter.com/essencechicago",
    instagram: "https://www.instagram.com/essencechicago/",
    yelp: "https://www.yelp.com/biz/essence-medspa-and-wellness-chicago",
  },
  googleAnalyticsID: "UA-153772759-1",
  siteRss: "/rss.xml",
  heywords: ["Medical Spa", "Day Spa", "Skin Care Service"],
  contact: {
    email: "keepcalm@essence-medspa.com",
    phone1: "+1 (773) 763-1212",
    phone2: "",
    address: "6413 N Kinzua Ave, Chicago, IL 60646",
    location: "X6XM+4W Chicago, Illinois",
  },
}
export default Config
