import React from "react"
import headerDefaultConfig from "~data/headerDefaultConfig"
export const headerDefault = headerDefaultConfig
const GlobalHeaderContext = React.createContext()

const GlobalHeaderProvider = ({ children }) => {
  const [header, setHeader] = React.useState(headerDefault)
  // const [isOpenCart, setIsOpenCart] = React.useState(false)

  const changeHeader = (headerConfig = headerDefault) => {
    setHeader({
      ...header,
      ...headerConfig,
    })
  }
  return <GlobalHeaderContext.Provider value={{ header, changeHeader }}>{children}</GlobalHeaderContext.Provider>
}

export default GlobalHeaderContext
export { GlobalHeaderProvider }
